import {Broker} from '../components/broker';

export function BrokerContainer() {

    const search = window.location.search;
    const params = new URLSearchParams(search);
    let securityCheck = params.get('i');

    return (
        <>
            { securityCheck === process.env.REACT_APP_SECURITY_CHECK
                ? <Broker />
                : <h2>These are not the Brokers you are looking for</h2>
            }
        </>
    );

}
