import './App.css';
import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import {BrokerContainer} from './containers/broker';

export default function App() {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<BrokerContainer />} />
            </Routes>
        </Router>
    );
}

